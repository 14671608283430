<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">시험채점</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div>
          <div class="form-inp sm">
            <v-select
              :items="$store.getters['userStore/GE_USER_COMPANY']"
              item-text="CD_NM"
              item-value="CD"
              outlined
              hide-details
              label="회사구분"
              placeholder="선택하세요"
              v-model="grid01Search.ASP_NEWCUST_KEY"
              @change="search()"
              :readonly="RDOnly"
            >
            <template v-slot:label>
                회사구분
              <v-icon color="#EF9191">mdi-circle-small</v-icon>
            </template>
            </v-select>
          </div>
          <div class="form-inp sm ml-2">
            <v-select
              :items="mixin_common_code_get(allCodeList,'PLT018','전체')"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              label="유형타입"
              placeholder="선택하세요"
              v-model="grid01Search.LM_TY"
            >
            </v-select>
          </div>
          <v-text-field
            class="form-inp ml-2"
            name="name" label="시험명" id="id" outlined hide-details=""
            v-model="grid01Search.LM_ID_NM"
            >
          </v-text-field>
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            content-class="calendar-modal"
            color="#FFF"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="searchDateRangeText"
                v-bind="attrs"
                v-on="on"
                readonly
                outlined
                hide-details
                class="form-inp icon-calendar ml-2"
                append-icon="svg-calendar"
                label="전체시험기간"
              ></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker
                v-model="grid01Search.START_DATE"
                no-title
                dark
                color="#F7AFAE"
                locale="ko-KR"
                :first-day-of-week="1"
                :day-format="mixin_getDate"
              ></v-date-picker>
              <v-date-picker
                v-model="grid01Search.END_DATE"
                dark
                color="#F7AFAE"
                no-title
                show-current="false"
                :min="grid01Search.START_DATE"
                locale="ko-KR"
                :first-day-of-week="1"
                :day-format="mixin_getDate"
              ></v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn
                outlined
                class="btn-default"
                @click="menu1 = false"
                >확인</v-btn
              >
            </div>
          </v-menu>
        </div>
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" @click="search()"> 조회 </v-btn>
        </div>
      </div>
    </div>
    <!-- 시험 리스트 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">시험 리스트</h2>
      <div class="box-ct">
        <v-data-table
          dense
          fixed-header
          :headers="grid01Headers"
          :items="grid01Data"
          item-key="LM_ID"
          hide-default-footer
          class="grid-default case03"
          height="260"
          @click:row="setGrid01Select"
          :no-data-text="noDataTable"
          :item-class="isActiveRow"
        >
        </v-data-table>
      </div>
    </div>

    <!-- 채점 대상자 리스트 -->
    <div class="box-wrap">
      <div class="mt-3">
        <h2 class="tit-h2 d-flex">채점 대상자 리스트 <p class="ml-2" v-show="isSelected">서술형여부 : {{loCount.LO_COUNT > 0 ? "Y" : "N"}}</p>
          <div class="ml-auto">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnStartChoice')" outlined class="btn-point" :disabled="!isSelected" @click="common_confirm('객관식을 채점하시겠습니까?',updateRtnLmGrading, '', null,'','chk')">객관식 채점</v-btn>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnStartScore')" outlined class="btn-point ml-2" :disabled="!isSelected || !isSelected2" @click="showDialog('M410401P01')">채점 시작</v-btn>
          </div>
          <v-dialog max-width="1400"
            persistent v-model="dialogM410401P01" v-if="dialogM410401P01"
          content-class="square-modal"
          >
            <M410401P01
              headerTitle="시험 채점"
              :LM_DATA_ID="grid02Selected.LM_DATA_ID"
              :grid01Selected="grid01Selected"
              :aspCustKey="grid01Search.ASP_NEWCUST_KEY"
              @hide="hideDialog('M410401P01')"
              @submit="submitAns()"
              @callback="selectRtnLmDetail()"
              :isView="false"
            >
            </M410401P01>
          </v-dialog>
        </h2>
        <div class="box-ct">
          <v-data-table
            dense
            fixed-header
            :headers="grid02Headers"
            :items="grid02Data"
            item-key="LM_DATA_ID"
            hide-default-footer
            class="grid-default case03"
            height="250"
            :no-data-text="noDataTable"
            :item-class="isActiveRow2"
            @click:row="setGrid02Select"
          >
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mixin} from '@/mixin/mixin.js'
import M410401P01 from './M410401P01.vue'
export default {
  name: "MENU_M410401", //name은 'MENU_' + 파일명 조합
  components: {
    M410401P01,
  },
  mixins : [mixin],
  data() {
    return {
      dialogM410401P01 : false,
      menu1 : false,
      grid01Headers:[
        {
          text: "No",
          align: "center",
          value: "LM_ID",
          width: "70px",
          sortable: true,
        },
        { text: "회사구분", value: "ASP_CUST_NM", align: "center", sortable: true },
        { text: "유형타입", value: "LM_TY_NM", align: "center", sortable: true },
        { text: "시험명", value: "LM_ID_NM", align: "center", sortable: true },
        { text: "진행상태", value: "LM_PG_ST_NM", align: "center", sortable: true },
        { text: "시험시작일", value: "LM_ST_DTTM", align: "center", sortable: true },
        { text: "시험종료일", value: "LM_EN_DTTM", align: "center", sortable: true },
        { text: "제한시간(분)", value: "LM_LIM_TIME", align: "center", sortable: true },
        { text: "문제수", value: "LM_EVA_RST_COUNT", align: "center", sortable: true },
        { text: "인원", value: "LM_DATA_COUNT", align: "center", sortable: true },
        { text: "제출자", value: "LM_DONE_CNT", align: "center", sortable: true },
        { text: "미제출자", value: "LM_NON_DONE_CNT", align: "center", sortable: true },
        { text: "총점", value: "LM_EVA_SUM", align: "center", sortable: true },
      ],
      grid01Data:[],
      grid01Selected: {},
      grid01Search:{
        ASP_NEWCUST_KEY : "",
        LM_TY : "",
        LM_NM : "",
        START_DATE : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        END_DATE : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
      grid02Headers:[
        {text : "성명", value:"LM_DATA_US_NM", align: "center", sortable: true},
        {text : "ID", value:"LM_DATA_US_ID", align: "center", sortable: true},
        {text : "객관식(단식)", value:"SC_TY1", align: "center", sortable: true},
        {text : "객관식(복식)", value:"SC_TY3", align: "center", sortable: true},
        {text : "서술형", value:"SC_TY2", align: "center", sortable: true},
        {text : "OX형", value:"SC_TY4", align: "center", sortable: true},
        {text : "총점", value:"SC_TOTAL", align: "center", sortable: true},
      ],
      grid02Data:[],
      grid02Selected : {},
      groupCdList : ['PLT012','PLT018', 'PLT019', 'PLT020', 'PLT021'],
      allCodeList : [],
      noDataTable:"검색된 결과가 없습니다.",
      loCount : {},
      
      RDOnly:false,
    };
  },
  async mounted(){
    this.allCodeList = await this.mixin_common_code_get_all(this.groupCdList);
  
    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.grid01Search.ASP_NEWCUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
    } else {
      this.RDOnly = false;
    }
  },
  methods: {
    setGrid01Select(item, row){
      console.log(item);
      console.log(row);
      // row.select(true);
      this.grid01Selected = item;
      this.grid02Selected = {};
      this.selectRtnLmDetail();
    },
    setGrid02Select(item){
      this.grid02Selected = item;
    },
    search(){
      this.grid01Selected = {};
      this.grid02Selected = {};
      this.grid02Data = [];
      this.selectRtnLmData();
    },
    async selectRtnLmData(){
      if(this.grid01Search.ASP_NEWCUST_KEY == undefined || this.grid01Search.ASP_NEWCUST_KEY == ''){
        this.common_alert("회사구분을 선택해주세요.","error");
      }else{
        let requestData = {
          headers: {},
          sendData:{}
        };
        // header 세팅
        requestData.headers["URL"] = "api/phone/lm/grading/list";
        requestData.headers["SERVICE"] = "phone.lm.grading";
        requestData.headers["METHOD"] = "list";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["ASYNC"] = false;

        // sendData 세팅
        requestData.sendData = this.grid01Search;
        requestData.sendData["USER_ID"] = this.$store.getters['userStore/GE_USER_ID'];

        // 결과 리턴
        let response = await this.common_postCall(requestData);

        if(!response.HEADER.ERROR_FLAG){
          this.grid01Data = response.DATA;
        }else{
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
        }

      }
    },
    async selectRtnLmDetail(){
      let requestData = {
          headers: {},
          sendData:{}
        };
        // header 세팅
        requestData.headers["URL"] = "api/phone/lm/grading/detail/inqire";
        requestData.headers["SERVICE"] = "phone.lm.grading.detail";
        requestData.headers["METHOD"] = "inqire";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["ASYNC"] = false;

        // sendData 세팅
        requestData.sendData = this.grid01Selected;

        // 결과 리턴
        let response = await this.common_postCall(requestData);

        if(!response.HEADER.ERROR_FLAG){
          this.grid02Data = response.DATA;
          this.loCount = response.LO_COUNT[0];
        }else{
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
        }
    },
    async updateRtnLmGrading(){
      if(this.grid02Data.length > 0){
        let requestData = {
          headers: {},
          sendData:{}
        };
        // header 세팅
        requestData.headers["URL"] = "api/phone/lm/grading/multi-choice/regist";
        requestData.headers["SERVICE"] = "phone.lm.grading.multi-choice";
        requestData.headers["METHOD"] = "regist";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["ASYNC"] = false;

        // sendData 세팅
        requestData.sendData = this.grid01Selected;
        requestData.sendData['USER_ID'] = this.$store.getters['userStore/GE_USER_ID'];
        requestData.sendData['USER_NM'] = this.$store.getters['userStore/GE_USER_NAME'];

        // 결과 리턴
        let response = await this.common_postCall(requestData);

        if(!response.HEADER.ERROR_FLAG){
          this.common_alert("정상적으로 처리되었습니다.","done");
          this.selectRtnLmDetail();
        }else{
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.","error");
        }
      }else{
        this.common_alert("채점할 대상자가 존재하지 않습니다.","error");
      }
    },
    showDialog(type) {
      console.log(type);
      this[`dialog${type}`] = true
    },
    hideDialog(type) {
      this[`dialog${type}`] = false
    },
    submitDialog(type) {
      this[`dialog${type}`] = false
    },
    rowClick (item, row) {
      row.select(true);
    },
    isActiveRow(item){
      return item.LM_ID == this.grid01Selected.LM_ID? 'active':'';
    },
    isActiveRow2(item){
      return item.LM_DATA_ID == this.grid02Selected.LM_DATA_ID? 'active' : '';
    },
  },
  computed: {
    searchDateRangeText(){
      var newStartDate = this.grid01Search.START_DATE;
      var newEndDate = this.grid01Search.END_DATE;
      return newStartDate + " ~ " + newEndDate;
    },
    isSelected(){
      let isSelected = false;

      if(this.grid01Selected.LM_ID != null && this.grid01Selected.LM_ID != ''){
        isSelected = true;
      }

      return isSelected;
    },
    isSelected2(){
      let isSelected = false;

      if(this.grid02Selected.LM_DATA_ID != null && this.grid02Selected.LM_DATA_ID != ''){
        isSelected = true;
      }

      return isSelected;
    },
  },
};
</script>

<style>

</style>
